/* eslint-disable operator-linebreak */
import { StyledFlexColumn } from 'common/styles/common.styles';
import React from 'react';
import { monitoredChains } from 'constants/constants';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import TvlByChain from './TvlByChain';

type Props = {
  vaults: MonitorVaults[];
  farms: AppSyncFarm[];
};

const TotalTvls: React.FC<Props> = (props) => {

  return (
    <StyledFlexColumn justifyContent='space-between' alignItems='start' className='px-3 py-1 mt-10'>
      {monitoredChains.map((chain) => (
        <TvlByChain
          key={chain}
          filter = {chain}
          vaults = {props.vaults} 
          farms = {props.farms}
        />
      ))}
    </StyledFlexColumn>
  );
};

export default TotalTvls;
