import { useQuery } from '@apollo/client';
import SpinnerContainer from 'common/components/SpinnerContainer';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { REFRESH_INTERVALS } from 'constants/constants';
import { listMonitorVaultsQuery } from 'graphql/queries/listMonitorVaults.query';
import React from 'react';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { listFarmsQuery } from 'graphql/queries/listFarms.query';
import TotalTvls from 'common/components/TotalTvls';

type Props = {
};

const MonitorVaultsContainer: React.FC<Props> = (props) => {
  const {
    loading: loadingFarms,
    error: errorLoadingFarms,
    data: dataFarms,
    networkStatus: networStatusFarms,
    refetch: refetchFarms
  } = useQuery<QueryItemsResponse<AppSyncFarm>>(listFarmsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listFarms]
  });
  const { loading, error, data } = useQuery<QueryItemsResponse<MonitorVaults>>(listMonitorVaultsQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listMonitorVaults]
  });

  const vaults: MonitorVaults[] = data?.listMonitorVaults?.items || [];

  const farms: AppSyncFarm[] = dataFarms?.listFarms.items || [];

  if (error) {
    console.error(error);
  }

  return (
    <SpinnerContainer isLoading={loading}>
      <TotalTvls 
        vaults={vaults} 
        farms={farms} />
    </SpinnerContainer>
  );
};

export default MonitorVaultsContainer;
