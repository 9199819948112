/* eslint-disable operator-linebreak */
import React from 'react';
import { ChainId } from '@ichidao/ichi-sdk/dist/src/crypto/networks';
import { AppSyncFarm, MonitorVaults } from 'common/models/monitorVaults';
import { FarmUtils } from 'utils/farmUtils';
import FormatFloat from './FormatPercent/FormatFloat';
import { MonitoredAmm } from 'constants/constants';

type Props = {
  chainId: ChainId;
  amm: MonitoredAmm;
  vaults: MonitorVaults[];
  farms: AppSyncFarm[];
};

const TvlByAmm: React.FC<Props> = (props) => {
  const getFarmByVault = (vault: MonitorVaults) =>{
    return props.farms.filter((f) => f.lpAddress.toLowerCase() === vault.address.toLowerCase())[0];
  }; 
  const filterCriteria = (v: MonitorVaults) => {
    const farm = getFarmByVault(v);
    if (!farm){
      return false;
    }
    const indicator = props.amm.indicator;
    const ammCriteria = indicator === '' 
      ? !v.displayName.replace('(legacy)', '').includes('(') && !v.displayName.replace('(legacy)', '').includes(')')
      : (v.displayName.includes(props.amm.indicator) || v.displayName.includes(props.amm.name.toLowerCase()));
  return farm.chainId === props.chainId && ammCriteria;
  };
  const tvl = props.vaults.filter((v) => filterCriteria(v)).reduce((total, v) => 
    {
      const farm = getFarmByVault(v);
      return total + v.tvl * FarmUtils.getBaseTokenPrice(farm)
    }, 0)

  return (
    <div 
      className='primary-text-color text-small'
    >
      {props.amm.buttonName}:&nbsp;  
      <span className=''>                
        <FormatFloat 
          value={tvl} 
          prefix='$' 
          displayDecimals={0}
        /> 
      </span> 

    </div>
  );
};

export default TvlByAmm;
